










import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import CenteredLayout from "@/components/layouts/CenteredLayout.vue";
import VueRouter from "vue-router";

const Auth = namespace("Auth");

Component.registerHooks([
    'beforeRouteEnter'
])


@Component({
    components: {
        CenteredLayout
    },
})
export default class NotFound extends Vue {
    async beforeRouteEnter(to: VueRouter, from: VueRouter, next) {
        next(vm => {
            const isLoggedIn = vm.$store.getters["Auth/isLoggedIn"];
            const result = vm.$router.resolve((from as any).path);

            if (isLoggedIn) {
                if (result.resolved.matched.length > 0) {
                    vm.$router.push({ path: (from as any).path });
                }
            }

            if (!isLoggedIn && (from as any).query["redirectUrl"] == undefined) {

                if (result.resolved.matched.length > 0) {
                    vm.$router.push({ path: "/", query: { "redirectUrl": (from as any).path } });
                } else {
                    return false;
                }
            }
            return false;
        });
    }
}
